<template>
  <div class="h5">
    <div class="px-5 pt-4">
      <div class="p3 text-bb-mid-grey pb-2">Account settings</div>
      <ul class="p2">
        <li
          v-for="(menuItem, index) in accessibleMenuItems"
          :key="index"
        >
          <bb-sidebar-menu-link :menu-item="menuItem" />
        </li>
      </ul>
    </div>

    <div class="p-4 bg-neutral-100">
      <a
        href="#"
        class="p3 text-bb-mid-grey"
        @click.prevent="handleLogout"
      >
        Sign out
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BbSidebarMenuLink from '@/components/layout/Sidebar/SidebarMenuLink.vue'

export default {
  name: 'MenuItemsAccount',
  components: { BbSidebarMenuLink },
  emits: ['navigation'],
  data() {
    return {
      menuItems: [
        {
          link: 'settings:account',
          name: 'Account Settings',
          bbIcon: true,
          icon: 'ic-account',
          standardUserAccess: false,
        },
        {
          link: 'settings:organization',
          name: 'Organizations',
          bbIcon: true,
          icon: 'ic-corporate-fare',
          standardUserAccess: true,
        },
        {
          link: 'settings:user-management',
          name: 'Users',
          bbIcon: true,
          icon: 'ic-user',
          standardUserAccess: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    standardUser() {
      return this.user.role.name === 'standard'
    },
    accessibleMenuItems() {
      return this.menuItems.filter(menuItem => menuItem.standardUserAccess || !this.standardUser)
    },
  },
  watch: {
    user(newValue) {
      if (!newValue) {
        this.$router.push({ name: 'signin' })
      }
    },
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
    }),
    async handleLogout() {
      await this.signOut()
      await this.$router.push({
        name: 'signin',
      })
    },
  },
}
</script>
