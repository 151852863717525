<template>
  <div
    v-click-outside="close"
    class="w-full h-full"
  >
    <div
      class="store-container"
      @click.prevent.stop="openNavbarDropdown"
    >
      <div class="mb-1 px-4 flex gap-3 justify-between p4 uppercase">
        <strong class="truncate text-xs font-light text-bb-text-secondary">
          {{ siteOrganizationName }}
        </strong>
      </div>
      <div class="flex items-center px-4 justify-between cursor-pointer space-x-2">
        <h4 class="h4 truncate-custom font-medium">
          {{ siteName }}
        </h4>
        <ic-chevron-up
          v-if="isOpen"
          :size="18"
          class="text-bb-mid-grey flex-none"
        />
        <ic-chevron-down
          v-else
          :size="18"
          class="text-bb-mid-grey flex-none"
        />
      </div>
    </div>
    <transition name="fade">
      <dropdown-menu
        v-if="isOpen"
        :organizations="userOrganization"
        :navbar-organization="$route.name === 'portfolio' ? null : selectedSite"
        @close="close"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import IcChevronDown from 'vue-material-design-icons/ChevronDown'
import IcChevronUp from 'vue-material-design-icons/ChevronUp'
import DropdownMenu from '@/components/layout/Navbar/OrganizationAndSite/DropdownMenu.vue'

export default {
  name: 'CustomerDropdown',
  components: { DropdownMenu, IcChevronDown, IcChevronUp },
  emits: ['close', 'connect-store-clicked'],
  data() {
    return {
      isOpen: false,
      organizations: null,
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('organization', ['userOrganization']),
    ...mapGetters({
      stores: 'store/stores',
      selectedStore: 'store/selectedStore',
      account: 'account/getAccount',
      user: 'auth/user',
    }),

    siteName() {
      return this.selectedSite && this.$route.name !== 'portfolio' ? this.selectedSite.label : 'Portfolio'
    },
    siteOrganizationName() {
      return this.selectedSite && this.$route.name !== 'portfolio' ? this.selectedSite.site_organization_name : ''
    },
  },
  async mounted() {
    if (!this.selectedSite) {
      await this.$router.push({ name: 'portfolio' })
      return
    }

    // Add a check to ensure userOrganization is not null or undefined
    if (!this.userOrganization) return

    const isCorrectOrganization = this.userOrganization.find(org => org.id === this.selectedSite.site_organization_id)
    if (!isCorrectOrganization) {
      await this.loadSelectedSite(null)
      await this.$router.push({ name: 'portfolio' })
    }
  },
  methods: {
    ...mapActions({
      loadSelectedSite: 'site/loadSelectedSite',
    }),

    close() {
      if (this.isOpen) {
        this.isOpen = !this.isOpen
      }
    },
    openNavbarDropdown() {
      // prevent opening while the app is not yet mounted
      if (!this.userOrganization) return
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss" scoped>
.store-container {
  @apply flex flex-col p-4  h-full justify-center cursor-pointer;

  .subheader {
    @apply text-bb-mid-grey font-semibold  flex-none;
    padding-top: 0.2rem;

    &__top {
      @apply block text-xs;
    }

    &__bottom {
      @apply hidden;
    }
  }

  .downgrade {
    @apply hidden;
  }

  @screen sm {
    @apply w-full;
    max-width: 640px;

    .downgrade {
      @apply flex items-center gap-1;
    }

    .subheader {
      &__top {
        @apply hidden;
      }

      &__bottom {
        @apply block;
      }
    }
  }
}
</style>
