<template>
  <span
    class="badge"
    :class="classes"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'badge',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    micro: {
      type: Boolean,
      default: false,
    },
    enterprise: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        micro: this.micro,
        small: this.small,
        enterprise: this.enterprise,
        medium: this.medium,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.badge {
  background: #f4f2ff;
  color: white;
  border-radius: 4px;
  flex-grow: 0;
  margin: 0 4px;
  text-transform: uppercase;

  padding: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 8px;

  &.micro {
    padding: 2px;
    font-weight: 700;
    font-size: 6px;
    line-height: 4px;
  }

  &.small {
    padding: 4px;
    font-weight: 700;
    font-size: 9px;
    line-height: 6px;
  }

  &.medium {
    padding: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 9px;
  }

  &.enterprise {
    background: linear-gradient(204.06deg, rgba(240, 75, 165, 0.85) 5.67%, rgba(240, 75, 165, 0) 94.25%), #f3a64c;
    border-radius: 10px;
  }
}
</style>
