<template>
  <div>
    <div class="flex flex-row justify-between">
      <div class="flex flex-row space-x-4">
        <icInfo
          v-if="type === 'info' || type === 'warning'"
          class="my-auto cursor-pointer"
        />
        <icCheckOutline
          v-if="type === 'success'"
          class="my-auto cursor-pointer"
        />
        <icCancel
          v-if="type === 'error'"
          class="my-auto cursor-pointer"
        />
        <div class="flex flex-col my-auto">
          <span class="p1 font-semibold">
            {{ title }}
          </span>
          <span class="p1">
            {{ message }}
          </span>
        </div>
      </div>
      <div
        v-if="hasAButton && buttonText !== ''"
        class="h-full my-auto px-4"
      >
        <slot name="toast-slot">
          <merge-button-round
            :button-type="buttonType"
            :class="buttonClass"
            @click="$emit('click')"
          >
            <p>{{ buttonText }}</p>
          </merge-button-round>
        </slot>
      </div>
      <icClose
        class="my-auto cursor-pointer"
        @click="$emit('close-toast')"
      />
    </div>
  </div>
</template>

<script>
import icInfo from 'vue-material-design-icons/InformationOutline'
import icCheckOutline from 'vue-material-design-icons/CheckCircleOutline'
import icClose from 'vue-material-design-icons/Close'
import icCancel from 'vue-material-design-icons/Cancel'

export default {
  name: 'BbToast',
  components: { icClose, icInfo, icCheckOutline, icCancel },
  props: {
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    hasAButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonType: {
      type: String,
      default: 'primary',
    },
    buttonClass: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.Vue-Toastification__container {
  z-index: 100000 !important;
  width: 100% !important;
  left: 0% !important;
  margin-left: 0 !important;
}

.Vue-Toastification__toast {
  max-width: 719px !important;
  min-width: 719px !important;
}

.Vue-Toastification__toast--error {
  color: #e12166 !important;
  background-color: #f9c7c6 !important;
  @apply border-bb-dull-red border;
}

.Vue-Toastification__toast--info {
  color: #1082a9 !important;
  background-color: #cbebfb !important;
  @apply border-bb-dull-blue border;
}

.Vue-Toastification__toast--warning {
  color: #dc7828 !important;
  background-color: #ffeebb !important;
  @apply border-bb-dull-orange border;
}

.Vue-Toastification__toast--success {
  color: #39b579 !important;
  background-color: #effff2 !important;
  @apply border-bb-dull-green border;
}
</style>
