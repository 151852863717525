const TYPE = {
  SET_OFFERINGS: 'SET_OFFERINGS',
  SET_ADGROUPS: 'SET_ADGROUPS',
  SET_OFFERING: 'SET_OFFERING',
}

export default {
  namespaced: true,
  state: {
    offerings: [],
    adgroups: [],
    offering: null,
  },
  getters: {
    offerings(state) {
      return state.offerings
    },
    adgroups(state) {
      return state.adgroups
    },
    offering(state) {
      return state.offering
    },
  },
  actions: {
    async fetchOfferings({ commit }, siteId) {
      try {
        const response = await axios.get(`/common/site/${siteId}/offering`)
        commit(TYPE.SET_OFFERINGS, response.data)
      } catch (error) {
        console.error('Failed to fetch offerings:', error)
      }
    },
    async fetchAdgroups({ commit }, siteId) {
      try {
        const response = await axios.get(`/common/site/${siteId}/adgroup`)
        commit(TYPE.SET_ADGROUPS, response.data)
      } catch (error) {
        console.error('Failed to fetch adgroups:', error)
      }
    },
    async fetchOffering({ commit, state }, { siteId, offeringId }) {
      try {
        const response = await axios.get(`/common/site/${siteId}/offering/${offeringId}`)
        const offering = {
          ...response.data,
          adgroups: response.data.adgroups.map(adgroup => {
            const foundAdgroup = state.adgroups.find(ag => ag.value === adgroup.id)
            return {
              value: adgroup.id,
              label: adgroup.name,
              subLabel: foundAdgroup ? foundAdgroup.subLabel : null,
            }
          }),
        }
        commit(TYPE.SET_OFFERING, offering)
      } catch (error) {
        console.error('Failed to fetch offering:', error)
      }
    },
  },
  mutations: {
    [TYPE.SET_OFFERINGS](state, offerings) {
      state.offerings = offerings
    },
    [TYPE.SET_ADGROUPS](state, campaigns) {
      state.adgroups = campaigns.flatMap(campaign =>
        campaign.adgroups.map(adgroup => ({
          value: adgroup.id,
          label: adgroup.name,
          subLabel: campaign.campaign_name,
        })),
      )
    },
    [TYPE.SET_OFFERING](state, offering) {
      state.offering = offering
    },
  },
}
