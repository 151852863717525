const TYPE = {
  LOAD_COMPETITOR_INSIGHTS: 'LOAD_COMPETITOR_INSIGHTS',
  LOAD_MONITORING_JOB: 'LOAD_MONITORING_JOB',
  LOAD_COMPETITION_RANKING: 'LOAD_COMPETITION_RANKING',
  LOAD_TOP_COMPETITORS: 'LOAD_TOP_COMPETITORS',
  LOAD_SAVINGS_INSIGHTS: 'LOAD_SAVINGS_INSIGHTS',
  LOAD_FREQUENCY_RANKS: 'LOAD_FREQUENCY_RANKS',
  LOAD_INFRINGEMENT_SUMMARY: 'LOAD_INFRINGEMENT_SUMMARY',
  LOAD_ALL_INFRINGEMENTS: 'LOAD_ALL_INFRINGEMENTS',
  LOAD_INFRINGEMENT: 'LOAD_INFRINGEMENT',
  LOAD_WHITELIST: 'LOAD_WHITELIST',
  LOAD_ALL_COMPETITORS: 'LOAD_ALL_COMPETITORS',
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  SET_OVERVIEW_LOADING: 'SET_OVERVIEW_LOADING',
  SET_INFRINGEMENTS_DETECTOR_LOADING: 'SET_INFRINGEMENTS_DETECTOR_LOADING',
  CLEAR: 'CLEAR',
}

const today = new Date()
const last30Days = new Date()
last30Days.setDate(today.getDate() - 30)
const DEFAULT_DATES = [last30Days, today]

export default {
  namespaced: true,
  state: {
    dateRange: DEFAULT_DATES,

    competitorInsights: {},
    monitoringJob: {},
    competitionRanking: [],
    topCompetitors: [],
    savingsInsights: {},
    frequencyRanks: {},
    frequencyRanksDataPoints: 12,
    infringementSummary: {},
    infringements: [],
    moreInfringements: {},
    whitelist: [],
    allCompetitors: [],

    isOverviewLoading: false,
    isInfringementsDetectorLoading: false,
  },
  getters: {
    isMonitoringJobStopped: state => state.monitoringJob.initialized === false,
  },
  actions: {
    loadCompetitorInsights({ commit }, data) {
      commit(TYPE.LOAD_COMPETITOR_INSIGHTS, data)
    },
    loadMonitoringJob({ commit }, data) {
      commit(TYPE.LOAD_MONITORING_JOB, data)
    },
    loadCompetitionRanking({ commit }, data) {
      commit(TYPE.LOAD_COMPETITION_RANKING, data)
    },
    loadTopCompetitors({ commit }, data) {
      commit(TYPE.LOAD_TOP_COMPETITORS, data)
    },
    loadSavingsInsights({ commit }, data) {
      commit(TYPE.LOAD_SAVINGS_INSIGHTS, data)
    },
    loadFrequencyRanks({ commit }, data) {
      commit(TYPE.LOAD_FREQUENCY_RANKS, data)
    },
    loadInfringementSummary({ commit }, data) {
      commit(TYPE.LOAD_INFRINGEMENT_SUMMARY, data)
    },
    loadAllInfringements({ commit }, data) {
      commit(TYPE.LOAD_ALL_INFRINGEMENTS, data)
    },
    loadInfringement({ commit }, data) {
      commit(TYPE.LOAD_INFRINGEMENT, data)
    },
    loadWhitelist({ commit }, data) {
      commit(TYPE.LOAD_WHITELIST, data)
    },
    loadAllCompetitors({ commit }, data) {
      commit(TYPE.LOAD_ALL_COMPETITORS, data)
    },
    setOverviewLoading({ commit }, data) {
      commit(TYPE.SET_OVERVIEW_LOADING, data)
    },
    setInfringementsDetectorLoading({ commit }, data) {
      commit(TYPE.SET_INFRINGEMENTS_DETECTOR_LOADING, data)
    },
    setDateRange({ commit }, data) {
      commit(TYPE.SET_DATE_RANGE, data)
    },
    resetDateRange({ commit }) {
      commit(TYPE.SET_DATE_RANGE, DEFAULT_DATES)
    },
    clear({ commit }) {
      commit(TYPE.CLEAR)
    },
  },
  mutations: {
    [TYPE.LOAD_COMPETITOR_INSIGHTS](state, data) {
      if (!data) {
        state.competitorInsights = {}
        return
      }
      state.competitorInsights = data
    },
    [TYPE.LOAD_MONITORING_JOB](state, data) {
      if (!data) {
        state.monitoringJob = {}
        return
      }
      state.monitoringJob = data
    },
    [TYPE.LOAD_COMPETITION_RANKING](state, data) {
      if (!data) {
        state.competitionRanking = []
        return
      }
      state.competitionRanking = data
    },
    [TYPE.LOAD_TOP_COMPETITORS](state, data) {
      if (!data) {
        state.topCompetitors = []
        return
      }
      state.topCompetitors = data
    },
    [TYPE.LOAD_SAVINGS_INSIGHTS](state, data) {
      if (!data) {
        state.savingsInsights = {}
        return
      }
      state.savingsInsights = data
    },
    [TYPE.LOAD_FREQUENCY_RANKS](state, data) {
      if (!data) {
        state.frequencyRanks = {}
        return
      }
      state.frequencyRanks = data
    },
    [TYPE.LOAD_INFRINGEMENT_SUMMARY](state, data) {
      if (!data) {
        state.infringementSummary = {}
        return
      }
      state.infringementSummary = data
    },
    [TYPE.LOAD_ALL_INFRINGEMENTS](state, data) {
      if (!data) {
        state.infringements = []
        return
      }
      state.infringements = data
    },
    [TYPE.LOAD_INFRINGEMENT](state, data) {
      if (!data || !data.competitorName) return

      state.moreInfringements = {
        ...state.moreInfringements,
        [data.competitorName]: data.infringements,
      }
    },
    [TYPE.LOAD_WHITELIST](state, data) {
      if (!data) {
        state.whitelist = []
        return
      }
      state.whitelist = data
    },
    [TYPE.LOAD_ALL_COMPETITORS](state, data) {
      if (!data) {
        state.allCompetitors = []
        return
      }
      state.allCompetitors = data
    },
    [TYPE.SET_DATE_RANGE](state, data) {
      if (!data) {
        state.dateRange = DEFAULT_DATES
        return
      }
      state.dateRange = data
    },
    [TYPE.SET_OVERVIEW_LOADING](state, data) {
      state.isOverviewLoading = data
    },
    [TYPE.SET_INFRINGEMENTS_DETECTOR_LOADING](state, data) {
      state.isInfringementsDetectorLoading = data
    },
    [TYPE.CLEAR](state) {
      state.competitorInsights = {}
      state.monitoringJob = {}
      state.competitionRanking = []
      state.topCompetitors = []
      state.savingsInsights = {}
      state.frequencyRanks = {}

      state.infringementSummary = {}
      state.infringements = []
      state.moreInfringements = {}
      state.whitelist = []
      state.allCompetitors = []

      state.dateRange = DEFAULT_DATES

      state.isOverviewLoading = false
      state.isInfringementsDetectorLoading = false
    },
  },
}
