/* eslint-disable no-undef */
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import router from './router'
import store from './store'
import permissions from './core/permissions.js'
import { mapState } from 'vuex'

if (require('./configs/sentry').allowSentry(false)) {
  const SentryConfig = require('@/configs/sentry')
  Sentry.init(SentryConfig.getRuntimeConfig(Vue))
}

Vue.config.productionTip = false

/**
 * Third party
 */

// Meta
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

// Axios
import axios from './core/axios/instance'

window.axios = axios
Vue.prototype.$http = axios

// Tailwind
import './assets/style/tailwind.css'

// Vue modal
import VModal from 'vue-js-modal'

Vue.use(VModal, {
  dynamicDefaults: {
    height: 'auto',
    adaptive: true,
    maxWidth: 550,
    width: '94%',
    classes: ['c-modal'],
    scrollable: true,
  },
})

import VueLazyload from 'vue-lazyload'
import { formatImage } from '@/utils/text-utils'

Vue.use(VueLazyload, {
  throttleWait: 3000,
  loading: require('../public/content/loading.gif'),
  error: require('../public/content/images/no-image.png'),
  filter: {
    // eslint-disable-next-line no-unused-vars
    productOverview(listener, options) {
      if (listener.el.id === 'product-img') {
        listener.src = formatImage(listener.src)
      }
    },
  },
  observer: true,

  // optional
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1,
  },
})

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const toastOptions = {
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true,
  position: 'bottom-center',
  timeout: 5000,
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  rtl: false,
  closeButton: false,
  icon: false,
}

Vue.use(Toast, toastOptions)

// Floating
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

Vue.use(FloatingVue, {
  themes: {
    menu: {
      triggers: ['hover', 'focus', 'touch'],
      delay: {
        hide: 0,
      },
    },
    sm: {
      $extend: 'menu',
    },
    lg: {
      $extend: 'menu',
    },
  },
})

// Vuelidate
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

// Google Tag Manager
import VueGtm from '@gtm-support/vue2-gtm'

Vue.use(VueGtm, {
  id: 'GTM-WNJSFVG',
  vueRouter: router,
  debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
})

/**
 * Global components
 */
import '@/components/global'

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      event.stopPropagation()
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

Vue.directive('indeterminate', (el, binding) => {
  el.indeterminate = !!(el.type && el.type === 'checkbox' && binding.value === null)
})

Vue.directive('scroll-to-me', {
  bind: function (el) {
    el.addEventListener('click', () => {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    })
  },
})

Vue.mixin({
  methods: {
    isAllowed: async function (resourceSlug, permissionSlug) {
      return permissions.isAllowed(resourceSlug, permissionSlug)
    },
  },
})

Vue.prototype.$trackGTMEvent = function (event, additionalData = {}) {
  const baseData = {
    event: event,
    account_id: this.selectedSite?.value, // Make sure selectedSite is available
  }

  // Merge baseData with any additional data passed to the function
  const eventData = {
    ...baseData,
    ...additionalData,
  }

  this.$gtm.trackEvent(eventData)
}

export default new Vue({
  store,
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('site', ['selectedSite']),
  },
  created() {
    // Set initial GTM data if user is already available at app load
    if (!this.user) return

    this.$gtm.trackEvent({
      bb_user_id: this.user.id,
      bb_user_external:
        this.user.email.includes('@brightbid') || this.user.email.includes('@speqta') ? 'Internal' : 'External',
    })
  },
  router,
  render: h => h(App),
}).$mount('#app')
