export default async function externalUserProtection({ to, next, store }) {
  const user = store.getters['auth/user']
  if (user.is_internal) return next()
  return next({
    name: 'dashboard',
    params: {
      nextUrl: to.fullPath,
    },
  })
}
